<template>
  <div>
    <page-section>
      <content-column>
        <recaptcha-challenge
          :on-success="onRecaptchaSuccess"
          :on-expire="onRecaptchaExpired"
          :action="recaptchaAction"
        />
      </content-column>
    </page-section>

    <page-section :margin-top="marginTop">
      <content-column :width="width">
        <braintree-dropin
          v-if="customer !== undefined"
          :margin-top="marginTop"
          :width="width"
          :error="error"
          :customer="customer"
          :on-ready-status-change="onReadyStatusChange"
          :amount="amount"
        />
      </content-column>
    </page-section>

    <purchase-actions
      :width="width"
      :disabled="disabled"
      :purchase-label="purchaseLabel"
      :on-purchase="onPurchaseClicked"
      :on-cancel="onCancel"
    />
  </div>
</template>

<script lang="ts">
import {PropType, computed, defineComponent, ref} from 'vue';
import PageSection from '../../core/page/PageSection.vue';
import ContentColumn from '../../core/compositions/ContentColumn.vue';
import RecaptchaChallenge from '../../generic/recaptcha-challenge/RecaptchaChallenge.vue';
import BraintreeDropin from './partials/BraintreeDropin.vue';
import PurchaseActions from './partials/PurchaseActions.vue';
import {Dropin} from 'braintree-web-drop-in';
import {StandardPageAreaWidth} from '../../core/column-layout/utils';
import {PageSectionMargin} from '../../core/page/PageSection.vue';
import {BackendErrorComposition} from '../../vue-composition/backend-error/backend-error';
import {useFullScreenLoader} from '../../vue-composition/loader/loader';
import {BraintreeCustomer, BraintreeNonce} from './types';
import {getCustomer, getNonce} from './utils';

/**
 * This component allows the user to select a Braintree payment method.
 * If successful, a "nonce" is created that can be passed to the Braintree
 * payment system (via the Craft backend) to charge funds from the payment
 * method; either a single transaction or initiate a subscription.
 */
export default defineComponent({
  components: {
    PageSection,
    ContentColumn,
    RecaptchaChallenge,
    BraintreeDropin,
    PurchaseActions
  },
  props: {
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'narrow'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'md'},
    error: {type: Object as PropType<BackendErrorComposition>, required: true},
    email: {type: String, required: true},
    amount: {type: Number, required: true},
    purchaseLabel: {type: String, default: 'Purchase'},
    onPurchase: {
      type: Function as PropType<
        (nonce: Readonly<BraintreeNonce>, customer: Readonly<BraintreeCustomer>) => Promise<void>
      >,
      required: true
    },
    onCancel: {type: Function as PropType<() => void>, default: undefined},
    recaptchaAction: {type: String, required: true}
  },
  setup(props) {
    const recaptchaToken = ref<any | undefined>(undefined);
    const customer = ref<BraintreeCustomer | undefined>(undefined);
    const dropin = ref<Dropin | undefined>(undefined);
    const dropinReady = ref(false);
    const loader = useFullScreenLoader();
    let hasInitiatedPurchase = false;
    const purchaseDisabled = ref(false);

    const onRecaptchaSuccess = async (token: any) => {
      recaptchaToken.value = token;

      if (customer.value === undefined) {
        props.error.clear();
        loader.setLoading(true, 'Please wait');
        customer.value = await getCustomer(props.email, token).catch(props.error.catcher);
        loader.setLoading(false);
      }
    };
    const onRecaptchaExpired = () => {
      recaptchaToken.value = undefined;
    };

    const onReadyStatusChange = (_dropin: Dropin, status: boolean) => {
      dropin.value = _dropin;
      dropinReady.value = status;
    };

    const disabled = computed(() => {
      if (props.error.error()) {
        return true;
      }
      if (dropin.value === undefined) {
        return true;
      }
      if (purchaseDisabled.value) {
        return true;
      }

      return !dropinReady.value || recaptchaToken.value === undefined;
    });

    const onPurchaseClicked = async () => {
      if (dropin.value === undefined) {
        throw new Error('Not initialized');
      }

      // Avoid making multiple purchases if this function is called more than once.
      if (hasInitiatedPurchase) {
        return;
      }
      hasInitiatedPurchase = true;
      purchaseDisabled.value = true;

      props.error.clear();
      loader.setLoading(
        true,
        'Validating your payment method. Please do not navigate away from this page.'
      );
      const nonce = await getNonce(dropin.value, props.email, props.amount).catch(
        props.error.catcher
      );
      loader.setLoading(false);
      if (nonce !== undefined && customer.value !== undefined) {
        await props.onPurchase(nonce, customer.value);
      } else {
        hasInitiatedPurchase = false;
        purchaseDisabled.value = false;
      }
    };

    return {
      disabled,
      onRecaptchaSuccess,
      onRecaptchaExpired,
      onPurchaseClicked,
      customer,
      onReadyStatusChange
    };
  }
});
</script>
